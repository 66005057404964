<template>
	<div class=" ptb-10 justify-space-between items-center search">

		<div
			v-show="option.is_cnt"
			class="flex-column justify-center"
		>
			<span >총 <span class="font-weight-bold color-red size-em-12 ">{{ option.tCnt | makeComma }}</span> 건</span>
		</div>

		<div class="flex-1 text-right">

			<select
				v-if="!option.not_multi"
				class="pa-5-10 ml-10 mr-10"
				v-model="search.site_info"
				@change="setSite"
			>
				<option
					value=""
				>연동 사이트</option>
				<option
					v-for="(site, s_index) in site_list"
					:key="'site_' + s_index"
					:value="site.site_id"
				>{{ site.site_name }}</option>
			</select>

			<slot
				name="first"
			></slot>

			<span class="position-relative" style="z-index: 1">

			<date_picker
				v-if="false"
				@click="setDate($event, search, 'sDate')"
				class="ml-10"
			></date_picker>
			<input
				v-if="option.sDate"
				v-model="search.sDate"
				class="pa-5-10 box  ml-10 text-center cursor-pointer"
				placeholder="검색 시작일"
				readonly

				@click="clear(); datePickerStart = !datePickerStart"
			/>

			<v-date-picker
				v-if="datePickerStart"
				v-model="search.sDate"
				no-title
				scrollable
				class="position-absolute "
				style="top: 30px; left: -95px; z-index: 2"
				@change="clear"
			></v-date-picker>
			</span>

			<span
				v-if="option.sDate && option.eDate"
			>
				~
			</span>

			<span class="position-relative" style="z-index: 1">
			<input
				v-if="option.eDate"
				v-model="search.eDate"
				class="pa-5-10 box  ml-10 text-center cursor-pointer"
				placeholder="검색 종료일"
				readonly

				@click="clear(); datePickerEnd = !datePickerEnd"
			/>

			<v-date-picker
				v-if="datePickerEnd"
				v-model="search.eDate"
				no-title
				scrollable
				class="position-absolute "
				style="top: 30px; left: -95px;"
				@change="clear"
			></v-date-picker>
			</span>

			<slot
				name="add"
			></slot>

			<template
				v-for="(select, index) in option.select"
			>
				<select
					:key="'select_' + index"
					v-model="search[select.column]"
					class="pa-5-10 box  ml-10 "
					@change="$emit('click', 1)"
				>
					<option
						:value="''"
					>{{ select.name }}</option>
					<option
						v-for="(item, item_index) in select.items"
						:key="'select_' + index + '_item_' + item_index"
						:value="item.column"
					>{{ item.name }}</option>
				</select>
			</template>

			<template
				v-if="!option.not_search"
			>
				<select
					class="pa-5-10 ml-10 "
					v-model="search.list_cnt"
					@change="$emit('click', 1)"
				>
					<option
						v-for="cnt in list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>

				<select
					class="pa-5-10 ml-10 "
					v-model="search.search_type"
				>
					<option
						:value="''"
					>검색조건</option>
					<option
						v-for="type in option.search_type"
						:key="'search_type_' + type.column"
						:value="type.column"
					>{{ type.name }}</option>
				</select>

				<input
					v-model="search.search_value"
					class="pa-5-10 box  ml-10 "
					placeholder="검색어를 입력하세요"
					@keyup.enter="$emit('click', 1)"
				/>
			</template>

			<button
				v-if="option.is_reset"
				class="btn-white pa-5-10  ml-10"
				@click="$emit('reset', 1)"
			>초기화</button>
			<button
				class="btn-blue pa-5-10  ml-10"
				@click="$emit('click', 1)"
			>검색</button>

			<button
				v-if="option.is_excel"
				class="btn-green pa-5-10  ml-10"
				@click="$emit('toExcel')"
			>EXCEL</button>

			<button
				v-if="is_item || option.is_item"
				class="btn-green pa-5-10  ml-10"
				@click="$emit('toItem')"
			>등록</button>

			<slot
				name="last"
			></slot>
		</div>
	</div>
</template>

<script>
import date_picker from "@/components/DatePicker"
import {Base64} from "js-base64";

	export default {
		name: 'Search'
		,props: ['search', 'option', 'is_item']
		,components: {date_picker}
		,data: function(){
			return {
				list_cnt: [10, 20, 30, 50, 100, 200, 500]
				,datePickerStart: false
				,datePickerEnd: false
			}
		}
		, computed: {

			site_list: function(){
				let t = []
				let d = sessionStorage.getItem('site_list')

				if(d){
					t = JSON.parse(Base64.decode(d))
				}

				let dd = sessionStorage.getItem('site_info')
				if(dd){
					dd = JSON.parse(Base64.decode(dd))
				}

				return t
			}
		}
		,methods:{
			clear: function(){
				this.datePickerStart = false
				this.datePickerEnd = false
			}
			, setDate: function(date) {
				let t = date.split('-')
				this.$emit('click', t)
			}
			, setSite: function(){

				if(!this.search.site_info || this.search.site_info == ''){
					alert('연동 사이트를 선택하세요')
				}else{
					this.site_list.filter( (item) => {
						if(item.site_id == this.search.site_info){
							sessionStorage.setItem('site_info', Base64.encode(JSON.stringify(item)))
							switch (item.site_id){
								case 'dailycon':
									this.$bus.$emit('setSkin', 'skin-G')
									this.$skin = 'skin-G'
									break;
								case 'dailyshop':
									this.$skin = 'skin-O'
									this.$bus.$emit('setSkin', 'skin-O')
									break;
								case 'dailygift':
									this.$skin = 'skin-B'
									this.$bus.$emit('setSkin', 'skin-B')
									break;
							}
						}
					})

					this.$set(this.search, 'page', 1)
					this.$emit('click', 1)
				}
			}
		}
		, created() {
			let d = sessionStorage.getItem('site_info')
			if(d){
				d = JSON.parse(Base64.decode(d))
				this.$set(this.search, 'site_info', d.site_id)
				// this.$emit('click', 1)
			}
		}
	}
</script>

<style>
.v-picker__body {border: 1px solid #bbb;}
.v-btn--active {background-color: #0f6ecd}

.search input
, .search select {
	border-radius: 4px;
	background-color: white;
}
</style>