<template>
	<div
		class="full-height flex-column bg-base" :class="skin"
	>
		<Loading
		></Loading>
		<Top
			v-if="program.top"
			:program="program"
			:user="user"
			:codes="codes"
			:Base64="Base64"
			:rules="rules"
			:TOKEN="TOKEN"
			:Axios="Axios"

			@push="toLocation"
			@setNotify="setNotify"
		></Top>
		<div
			class="full-height justify-space-between gap-40 overflow-y-auto"
		>
			<Side
				v-if="is_side"
				:Axios="Axios"
				:user="user"
				:program="program"

				@toggleSide="toggleSide"
				@push="toLocation"
			>
			</Side>

			<div class="flex-1 pa-10 full-height flex-column overflow-y-auto">

				<Title
					v-if="program.title"
					:program="program"
					:cart_cnt="cart_cnt"

					@push="toLocation"
				></Title>

				<Search
					v-if="program.search"
					:program="program"
					@toggleSide="toggleSide"
				></Search>

				<router-view
					:Axios="Axios"
					:Notify="Notify"
					:metaInfo="metaInfo"
					:rules="rules"
					:TOKEN="TOKEN"
					:cart_items="cart_items"
					:user="user"
					:filter="filter"
					:key="$route.fullPath"
					:date="date"
					:codes="codes"
					:site_list="site_list"

					@setNotify="setNotify"
					@onLoad="setProgram"
					@push="toLocation"
					@goBack="goBack"

					@onLoading="onLoading"
					@offLoading="offLoading"
					class=" full-height flex-column overflow-y-auto container"
				></router-view>
			</div>
		</div>

		<Notify
			:msg="notifyCondition.message"
			:type="notifyCondition.type"

			@clearMsg="clearMsg"
		/>


		<Popup_confirm
			v-if="is_on_notice"
			:not_cancel="true"
			@click="toMypage"
		>
			<template
				v-slot:title
			>보안 설정 안내</template>
			<template
				v-slot:content
			>SMS 인증 및 PIN 설정 후 이용하세요</template>
		</Popup_confirm>

		<AgencySmsConfirm
			v-if="is_on_sms"
			:user="user"
			:type="'auth'"
		></AgencySmsConfirm>

		<Pin
			v-if="user.account_id"
			:user="user"
		></Pin>
	</div>
</template>

<script>

	import Notify from '@/components/AlertMsg'
	import Side from "@/view/Layout/Side";
	import Top from "@/view/Layout/Top";
	import Title from "@/view/Layout/Title";
	import Search from "@/view/Layout/Search";
	import Loading from "@/view/Layout/Loading";
	import AgencySmsConfirm from "../Agency/AgencySmsConfirm";
	import Popup_confirm from "./PopupConfirm";
	import {Base64} from "js-base64";
	import Pin from "./Pin";
	
	export default{
		name: 'Layout'
		,props: ['Axios', 'Notify', 'metaInfo', 'rules', 'TOKEN', 'user', 'filter', 'date', 'codes', 'Base64', 'site_list']
		,components: {Pin, Popup_confirm, AgencySmsConfirm, Loading, Search, Title, Side, Top, Notify }
		,data: function(){
			return {
				program: {
				}
				,notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
				,is_side: true
				,cart_items: [

				]
				,category_list: null
				,supply_list: null
				,is_loading: false
				, is_on_sms: false
				, is_on_notice: false
				, skin: this.$skin
			}
		}
		,computed:{
			cart_cnt: function(){
				return this.cart_items.length
			}
		}
		,methods: {
			setNotify: function({type, message}){
				this.notifyCondition = {
					message: message
					,type: type
				}
			}
			,clearMsg: function(){
				this.notifyCondition.message = ''
			}
			,toggleSide: function(){
				this.is_side = !this.is_side
			}
			,setProgram: function(program){
				this.program = program
			}
			,toLocation: function({ name, params, query, not_query}){
				this.$router.push({ name: name, params: params, query: query}).catch(function(e){
					console.log(e)
				});

				if(!not_query) {
					localStorage.removeItem('query')
					this.query = {}
				}else{
					this.setQuery()
				}
			}
			,setQuery: function(){
				let store = localStorage.getItem('query')
				if(store){
					let query = JSON.parse(store)
					this.query = query
				}
			}
			,goBack: function(){
				this.$router.back()
			}
			,onLoading: function(){
				this.is_loading = true
			}
			,offLoading: function(){
				setTimeout(() => {
					this.is_loading = false
				}, 300)
			}
			, toMypage: function(){
				this.is_on_notice = false
				this.$router.push({ name: 'MypageAgency'})
			}

			, pinCheck: function(){

				this.$bus.$off('pinCallback')
				if(!sessionStorage.getItem('is_pin') && this.$route.fullPath.indexOf('Mypage/Agency') < 0){
					let TOKEN = sessionStorage.getItem(Base64.encode(process.env.VUE_APP_NAME) + 'AT')
					if(TOKEN){
						this.$bus.$emit('onPin', {
							type: 'auth'
						})

						this.$bus.$on('pinCallback', () => {
							sessionStorage.setItem('is_pin', true)
						})
					}
				}
			}
		}
		,created: function(){
			//this.getCategoryList()
			//this.getSupplyList()

			this.$bus.$on('onLoad', (program) => {
				this.setProgram(program)
			})

			this.$bus.$on('setSkin', (skin) => {
				this.skin = skin
			})

			this.$bus.$on('download', (type, folder, file) => {
				// console.log(type, folder, file)

				let site_info = ''
				if(!site_info){
					let dd = sessionStorage.getItem('site_info')

					if(dd){
						dd = JSON.parse(Base64.decode(dd))

						site_info = dd.site_id
					}
				}

				let site_list = sessionStorage.getItem('site_list')
				site_list = JSON.parse(Base64.decode(site_list))

				if(!site_info) {
					if (site_list) {
						site_info = site_list[0].site_id
					}
				}

				let baseUrl = ''
				site_list.filter((site) => {
					if (site.site_id == site_info) {
						baseUrl = 'https://' + site.site_domain + '/shop_api/'
					}
				})
				window.open(baseUrl + '/global/download?type=' + type + '&folder=' + folder + '&file_name=' + file, "thumb")
			})
			setTimeout( () => {
				this.pinCheck()
			}, 100)
		}
		,watch: {

			$route(){

				this.pinCheck()
				let skip = ['Index', 'Admin', 'Auth', 'Mypage'];
				if(this.user.account_type_code == 'A001003' && this.user.uid && !this.user.sms_auth_uid && this.$route.name != 'MypageAgency'){
					this.is_on_notice = true
				}else{
					let site_info = sessionStorage.getItem('site_info')
					let is_site = false
					if(!site_info){
						skip.filter( ( item ) => {
							if (this.$route.path.indexOf(item) > -1) {
								is_site = true
							}
						})
					}else{
						is_site = true
					}
					if(!is_site){
						// this.$bus.$emit('notify', { type: 'error', message: '설정된 사이트 정보가 없습니다.'})
						// this.$storage.push({name: 'Index'})
					}
				}
			}
			, user: {
				deep: true
				, immediate: true
				, handler: function(call){
					// console.log('watch user', call)
					if(this.user.account_type_code == 'A001003'){

						if(this.user.uid && !call.sms_auth_uid && this.$route.name != 'MypageAgency'){
							this.is_on_notice = true
						}else{
							if(call.uid && !call.is_pin){
								// console.log(123, call.uid, call.is_pin)
								setTimeout( () => {
									if(!sessionStorage.getItem(process.env.VUE_APP_NAME + 'S')){
										this.is_on_sms = true
										// this.$bus.$emit('onPin', { type: 'auth'})
									}
								}, 100)
							}
						}
					}
				}
			}

		}
	}
	
</script>

<style>
@import "../../assets/css/reset.css";
@import "../../assets/css/template.css";
@import "../../assets/css/templateO.css";
@import "../../assets/css/templateB.css";
@import "../../assets/css/base.css";
@import "../../assets/css/baseO.css";
@import "../../assets/css/baseB.css";

.cart-count {
	position: absolute;
	right: 10px;
	top: 5px;
	background-color: #0093e2;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: white;
	font-size: 12px;
	padding: 3px 5px;
	line-height: 100%;
}


.company-list {
	font-family: "\B9D1\C740 \ACE0\B515",Malgun Gothic,Apple-Gothic,Helvetica,Dotum,\\B3CB\C6C0,Gulim
}

.cart-none-list {
	display: none;
}

.main-box-pdt { background-color: white; border-radius: 10px 0px 0px 0px;}
.main-box-pdt img { border-radius: 10px 0px 0px 0px;}

</style>